@use '../utils' as *;

// theme title css here

.tp-section-subtitle {
    font-family: var(--tp-ff-heading);
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.18em;
    text-transform: uppercase;
    display: inline-block;
}

.tp-subtitle-before {
    font-family: var(--tp-ff-body);
    font-weight: 400;
    font-size: 20px;
    position: relative;
    text-transform:none;
    letter-spacing: 0;
    line-height: 1.5;
    @media #{$xs} {
        font-size: 17px;
    }
    &::before {
        content: '';
        transform: translateY(-5px);
        width: 40px;
        height: 2px;
        background-color: var(--tp-text-body);
        display: inline-block;
        margin-right: 15px;
    }
}

.tp-section-title {
    font-size: 43px;
    font-weight: bold;
    color: #662F85;
    & br {
        @media #{$xs} {
            display: none;
        }
    }

    @media #{$lg,$md} {
        font-size: 35px;
        padding-right: 0;
    }

    @media #{$sm,$xs} {
        font-size: 26px;
        padding-right: 0;
        margin-bottom: 15px;
    }
}
.tp-section-title-large{
    font-size: 53px;
    line-height: 1.2;
    @media #{$md} {
        font-size: 35px;
    }
    @media #{$lg} {
        font-size: 43px;
    }
    @media #{$xs} {
        font-size: 25px;
    }
}


.tp-section-subtitle-sm {
        font-family: var(--tp-ff-heading);
        font-style: normal;
        font-weight: var(--tp-fw-medium);
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 0.18em;
        color: var(--tp-grey-1);
    }

.tp-section-title-sm {
    font-family: var(--tp-ff-body);
    font-size: 30px;
    padding: 20px 0;
    color: var(--tp-theme-1);
}
.tp-title-size{
    font-size: 36px;
    margin-bottom:22px;
    margin-right: 50px;
    @media #{$xs,$xl} {
        margin-right: 0;
    }
}

.tp-white-text {
    padding: 50px 99px 60px 60px;
    color: var(--tp-common-white);
    font-family: var(--tp-ff-heading);

    @media #{$xxl} {
        padding: 40px;
    }
    @media #{$xl} {
        padding: 35px;
    }

    @media #{$xs} {
        padding-right: 15px;
        padding-left: 15px;
    }

    & p {
        line-height: 1.6;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: normal;
        text-transform: none;
        color: var(--tp-text-2);
    }
}

.tp-white-text-sm {
    font-size: 30px;
    padding: 20px 0;
    color: var(--tp-common-white);
    font-family: var(--tp-ff-heading);
}

.tp-pt-size {
    font-size: 40px;
    @media #{$xs} {
        font-size: 30px;
    }
}