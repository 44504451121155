@use '../utils' as *;

/*----------------------------------------*/
/*  10. TESTIMONIAL CSS START
/*----------------------------------------*/

.tp-testimonial-area {
    
    @media #{$lg} {
        padding: 50px 0 20px 0;
    }

    @media #{$xs} {
        padding-right: 0;
        padding-left: 0;
        padding-top: 50px;
        padding-bottom: 50px;
    }
}

.tp-testimonial {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 2rem 0;
    background: linear-gradient(112deg, #ffffff 50%, rgb(215, 165, 243) 50%);
    max-width: 1000px;
    margin: auto;

    & .slick-arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        @media #{$lg} {
            top: 100%;
            left: 0;
            right: 0;
            margin: auto;
            margin-top: 30px;
        }
        & i {
            font-size: 17px;
            width: 55px;
            height: 55px;
            border: 1px solid var(--tp-border-1);
            line-height: 55px;
            border-radius: 50%;
            // background-color: rgb(223, 56, 89);
        }
    }

    & .slick-prev {
        z-index: 1;
        left: -120px;
        background-color: rgb(102, 47, 133);

        &::after {
            position: absolute;
            top: 52%;
            right: -26px;
            content: "";
            width: 55px;
            height: 1.3px;
            background-color: var(--tp-common-black);
        }
    }

    & .slick-next {
        right: -120px;
        background-color: rgb(102, 47, 133);

        &::before {
            position: absolute;
            top: 52%;
            left: -26px;
            content: "";
            width: 55px;
            height: 1.3px;
            background-color: var(--tp-common-black);
        }
    }
}
.carousel-caption {
    position: initial;
    z-index: 10;
    padding: 2rem 3rem;
    color: rgba(78, 77, 77, 0.856);
    text-align: center;
    font-size: 1.2rem;
    font-style: italic;
    font-weight: bold;
    line-height: 2rem;
}
@media(max-width:767px){
    .carousel-caption {
        position: initial;
        z-index: 10;
        padding: 1rem 1.5rem;
        color: rgba(78, 77, 77, 0.856);
        text-align: center;
        font-size: 0.7rem;
        font-style: italic;
        font-weight: bold;
        line-height: 1.5rem;
    }
}
.test>i{
    background-color: rgb(102, 47, 133);
    padding: 1.4rem;
}
@media(max-width:767px){
    .test >i{
        padding: 0.8rem;
    }
}
.carousel-control-prev{
    justify-content: flex-start;
}

.carousel-control-next {
    justify-content: flex-end;
}
.carousel-control-prev,.carousel-control-next{
    transition: none;
    opacity: unset;
}
.tp-testi-reivew {
    // font-size: 30px;
    line-height: 1.5;

    & br {
        @media #{$lg,$md,$sm,$xs} {
            display: none
        }
    }

    @media #{$sm,$xs} {
        font-size: 20px;
    }
}
.tp-testi-reivew img{
    width: 6rem;
    border-radius: 5rem;
    margin-top: 1rem;
}
@media(max-width:767px){
    .tp-testi-reivew img{
        width: 4rem;
        border-radius: 4rem;
        margin-top: 1rem;
    }
}
.tp-testi-reviewer {
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: .18em;

}