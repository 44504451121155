.whocanol {
    margin: 0;
    list-style: none;
    padding: 0;
    --hue: 1;
    --unit: 1rem;
  }
  h1 {
    text-align: center;
  }
  p {
    line-height: 1.3;
    text-align: justify;
  }
  .event-date {
    margin: 2.25rem 0.75rem 0.25rem;
    font-weight: bold;
    color: #fff
  }
  .event-description {
    margin: 5px;
    color: #fff
  }
  .whocanli {
    --height: 8rem;
    position: relative;
    display: block;
    color: #fff;
    background-color: #662F85;
    border-color: linear-gradient(to bottom right, #ff3cac, #562b7c, #2b86c5);
    padding: 1rem;
    margin: 2rem 0;
    
  }
  .whocanli::before {
    content: "";
    background-color: inherit;
    position: absolute;
    display: block;
    width: var(--unit);
    height: var(--unit);
    top: 100%;
    left: calc(50% - (var(--unit)/2));
  }
  .whocanli::after {
    content: "";
    position: absolute;
    display: block;
    top: calc(100% + var(--unit));
    left: calc(50% - (var(--unit)));
    border: var(--unit) solid transparent;
    border-top-color: #662F85;
  }
  .whocanli:last-child::before,
  .whocanli:last-child::after {
    content: none;
  }
  .whocanli:nth-child(20n+1){
    --hue: 1;
  }
  .whocanli:nth-child(20n+2){
    --hue: 2;
  }
  .whocanli:nth-child(20n+3){
    --hue: 3;
  }
  .whocanli:nth-child(20n+4){
    --hue: 4;
  }
  .whocanli:nth-child(20n+5){
    --hue: 5;
  }
  .whocanli:nth-child(20n+6){
    --hue: 6;
  }
  .whocanli:nth-child(20n+7){
    --hue: 7;
  }
  .whocanli:nth-child(20n+8){
    --hue: 8;
  }
  .whocanli:nth-child(20n+9){
    --hue: 9;
  }
  .whocanli:nth-child(20n+10){
    --hue: 10;
  }
  .whocanli:nth-child(20n+11){
    --hue: 11;
  }
  .whocanli:nth-child(20n+12){
    --hue: 12;
  }
  .whocanli:nth-child(20n+13){
    --hue: 13;
  }
  .whocanli:nth-child(20n+14){
    --hue: 14;
  }
  .whocanli:nth-child(20n+15){
    --hue: 15;
  }
  .whocanli:nth-child(20n+16){
    --hue: 16;
  }
  .whocanli:nth-child(20n+17){
    --hue: 17;
  }
  .whocanli:nth-child(20n+18){
    --hue: 18;
  }
  .whocanli:nth-child(20n+19){
    --hue: 19;
  }
  .whocanli:nth-child(20n+20){
    --hue: 20;
  }
  @media (min-width: 550px) and (max-width: 899px){
    .whocanli {
      margin: 1rem;
      width: calc(50% - 4rem);
      float: left;
      min-height: var(--height);
    }
    .whocanli:nth-child(4n+3),
    .whocanli:nth-child(4n+4) {
      float: right;
    }
    .whocanli:nth-child(4n+1)::before {
      top: calc(var(--height)/2 + var(--unit)/2);
      left: 100%;
    }
    .whocanli:nth-child(4n+1)::after {
      top: calc(var(--height)/2);
      left: calc(100% + (var(--unit)));
      border: var(--unit) solid transparent;
      border-left-color: #662F85;
    }
    .whocanli:nth-child(4n+3)::before {
      top: calc(var(--height)/2 + var(--unit)/2);
      left: -1rem;
    }
    .whocanli:nth-child(4n+3)::after {
      top: calc(var(--height)/2);
      left: -3rem;
      border: var(--unit) solid transparent;
      border-right-color: #662F85;
    }
  }
  @media (min-width: 900px) and (max-width: 1199px){
    .whocanli {
      margin: 1rem;
      width: calc(33.33% - 4rem);
      float: left;
      min-height: 7rem;
    }
    .whocanli:nth-child(6n+4),
    .whocanli:nth-child(6n+5),
    .whocanli:nth-child(6n+6) {
      float: right;
    }
    .whocanli:nth-child(6n+1)::before,
    .whocanli:nth-child(6n+2)::before {
      top: calc(var(--height)/2 + var(--unit)/2);
      left: 100%;
    }
    .whocanli:nth-child(6n+1)::after,
    .whocanli:nth-child(6n+2)::after {
      top: 3.5rem;
      left: calc(100% + (var(--unit)));
      border: var(--unit) solid transparent;
      border-left-color: #662F85;
    }
    .whocanli:nth-child(6n+4)::before,
    .whocanli:nth-child(6n+5)::before{
      top: calc(var(--height)/2 + var(--unit)/2);
      left: -1rem;
    }
    .whocanli:nth-child(6n+4)::after,
    .whocanli:nth-child(6n+5)::after{
      top: calc(var(--height)/2);
      left: -3rem;
      border: var(--unit) solid transparent;
      border-right-color: #662F85;
    }
  }
  @media (min-width: 1200px){
    .whocanol {
      max-width: 1600px;
      margin: 0 auto;
    }
    .whocanli {
      margin: 1rem;
      width: calc(26% - 3rem);
      float: left;
      min-height: 9rem;
    }
    .whocanli:nth-child(8n+5),
    .whocanli:nth-child(8n+6),
    .whocanli:nth-child(8n+7),
    .whocanli:nth-child(8n+8){
      float: right;
    }
    .whocanli:nth-child(8n+1)::before,
    .whocanli:nth-child(8n+2)::before,
    .whocanli:nth-child(8n+3)::before{
      top: calc(var(--height)/2 + var(--unit)/2);
      left: 100%;
    }
    .whocanli:nth-child(8n+1)::after,
    .whocanli:nth-child(8n+2)::after,
    .whocanli:nth-child(8n+3)::after{
      top: calc(var(--height)/2);
      left: calc(100% + (var(--unit)));
      border: var(--unit) solid transparent;
      border-left-color: #662F85;
    }
    .whocanli:nth-child(8n+5)::before,
    .whocanli:nth-child(8n+6)::before,
    .whocanli:nth-child(8n+7)::before {
      top: calc(var(--height)/2 + var(--unit)/2);
      left: -1rem;
    }
    .whocanli:nth-child(8n+5)::after,
    .whocanli:nth-child(8n+6)::after,
    .whocanli:nth-child(8n+7)::after {
      top: calc(var(--height)/2);
      left: -3rem;
      border: var(--unit) solid transparent;
      border-right-color: #662F85;
    }
  }